.card-plain{
    background: transparent;
    box-shadow: none;
    .card-body,
    .card-header{
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-header:after{
        width: 100%;
        left: 0;
    }
}

.card-profile, .card-testimonial {
    margin-top: 60px;
    text-align: center;
    min-width: 19em;
    max-width: 35em;
}

.card-body {
    min-height: 12em;
}

.card-profile .card-avatar, .card-testimonial .card-avatar {
    max-width: 130px;
    max-height: 130px;
    margin: -60px auto 0;
}

.card-profile .card-avatar .profile-span, .card-testimonial .card-avatar .profile-span {
    border-radius: 50%!important;
    background-color: orangered;
    display: block;
    width:130px;
    height:130px;
    color: white;
    padding-top: 55px;
    font-size: large;
}

.card-profile .card-avatar img, .card-testimonial .card-avatar img {
    border-radius: 50%!important;
}

.card-profile .card-avatar i, .card-testimonial .card-avatar i {
    width: 130px;
    height: 130px;
    font-size: 100px;
    padding-top: 10px;
    background-color: white;
    color: #626262fa;
    border-radius: 50%;
}

.card-item {
    display: block;
    line-height: 2em;
    font-size: 1.1em;
}

.card-item .small{
    display: block;
    line-height: 2em;
    font-size: 1.1em;
}

h4.card-title  {
    font-size: 1.76em !important;
}